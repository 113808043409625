import Vue from 'vue';
import { defaultTo } from 'lodash';
import { ConfigurationService } from '@nsftx/games-config';
import {
  busService,
  queryStringParser,
  sentry,
} from '@/utility';
import filters from './filters';
import App from './App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import translations from './i18n';

Vue.config.productionTip = false;

Vue.use(filters);

(async () => {
  const queryParams = queryStringParser.parse(window.location.search) || {};
  console.log(' queryParams ', queryParams);
  const configService = new ConfigurationService({
    environment: process.env.VUE_APP_ENVIRONMENT,
    applicationName: defaultTo(queryParams.application, 'Admin'),
    productName: defaultTo(queryParams.slaveId, 'VirtualSoccer').replace('Plugin', ''),
    requiredPaths: [
      'user',
      'productId',
      'tenantId',
      'locale',
    ],
    messageProtocol: 'gravityGateway',
    clientAdapters: [
      {
        name: 'GravityGatewayAdapter',
        options: {
          isDebug: true,
          slaveId: queryParams.slaveId || '',
          eventPropagation: {
            click: true,
          },
          eventListeners: {
            scroll: true,
          },
        },
      },
    ],
  });
  try {
    const config = await configService.getConfiguration();
    const selectedTicketColumns = JSON.parse(localStorage.getItem('vsSelectedColumns'));
    if (selectedTicketColumns) {
      store.state.selectedHeaders.tickets = selectedTicketColumns;
    }
    console.info(' Config local new', config);
    store.state.config = config;
    store.state.offer = config.state.offer;
    store.state.user = config.user;

    Vue.prototype.$config = configService;

    busService.init();
  } catch (error) {
    console.warn(' Invalid Configuration Error: ', error);
  }

  if (process.env.VUE_APP_SENTRY_ACTIVE === 'true') {
    sentry.start(Vue);
  }

  new Vue({
    router,
    store,
    async created() {
      await translations(store.getters.config.locale).then(({ default: localTranslations }) => {
        store.dispatch('setTranslations', localTranslations);
      });
    },
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
})();
