import {
  startOfMonth,
  startOfWeek,
  sub,
  format,
  endOfMonth,
} from 'date-fns';

const formatDate = (val) => format(val, 'yyyy-MM-dd');

const todayDate = formatDate(new Date());
const previousDay = formatDate(sub(new Date(), { days: 1 }));
const currentWeek = formatDate(startOfWeek(new Date(), { weekStartsOn: 1 }));
const previousWeek = formatDate(sub(new Date(), { weeks: 1 }));
const currentMonth = formatDate(startOfMonth(new Date()));
const previousMonth = formatDate(startOfMonth(sub(new Date(), { months: 1 })));
const previousMonthEnd = formatDate(endOfMonth(sub(new Date(), { months: 1 })));

const updateDateRange = (val) => {
  let startDate = '';
  let endDate = '';
  switch (val) {
    case 'currentDate':
      startDate = todayDate;
      endDate = todayDate;
      break;
    case 'previousDay':
      startDate = previousDay;
      endDate = previousDay;
      break;
    case 'currentWeek':
      startDate = currentWeek;
      endDate = todayDate;
      break;
    case 'previousWeek':
      startDate = previousWeek;
      endDate = todayDate;
      break;
    case 'currentMonth':
      startDate = currentMonth;
      endDate = todayDate;
      break;
    case 'previousMonth':
      startDate = previousMonth;
      endDate = previousMonthEnd;
      break;
    default:
      break;
  }
  return { startDate, endDate };
};

export default {
  formatDate,
  todayDate,
  previousDay,
  currentWeek,
  previousWeek,
  currentMonth,
  previousMonth,
  updateDateRange,
};
