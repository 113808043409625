/* eslint-disable */
import { dateTime } from "@/utility";
import types from "./mutationTypes";

export default {
  [types.SET_CONFIG](state, payload) {
    state.config = payload;
  },
  [types.SET_OFFER](state, payload) {
    state.offer = payload;
  },
  [types.SET_USER](state, payload) {
    state.user = payload;
  },
  [types.SET_INFO](state, payload) {
    state.info = payload;
  },
  [types.SET_DATA](state, payload) {
    state.data = payload;
  },
  [types.SET_HEADERS](state, payload) {
    state.headers = payload;
  },
  [types.SET_SELECTED_HEADERS](state, payload) {
    state.selectedHeaders.tickets = payload;
  },
  [types.SET_PAGINATION](state, payload) {
    state.pagination = payload;
  },
  [types.SET_TOTALS](state, payload) {
    state.totals = payload;
  },
  [types.SET_FILTERS](state, payload) {
    state.filters = payload;
  },
  [types.SET_TICKET](state, payload) {
    state.ticket = payload;
  },
  [types.SET_BARCODE](state, payload) {
    state.barcode = payload;
  },
  [types.RESET_DATA](state) {
    state.data.length = 0;
    state.headers.length = 0;
    state.pagination = null;
    state.totals = null;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = payload;
  },
  [types.UPADTE_PARAMS](state, payload) {
    state.params = payload;
  },
  [types.RESET_PARAMS](state) {
    state.params = {
      limit: 25,
      startDate: new Date(`${dateTime.todayDate}:00:00:00`).toISOString(),
      endDate: new Date(`${dateTime.todayDate}:23:59:59`).toISOString(),
      page: 1,
    };
  },
  [types.SET_ACTIVE_CHANNEL](state, payload) {
    state.activeChannel = payload;
  },
};
