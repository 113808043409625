import { each } from 'lodash';
import store from '@/store';

export default function parseOutcome(key) {
  const offers = store.state.offer;
  let outcomeName;
  each(offers, (offer) => {
    each(offer.outcomes, (outcome) => {
      if (outcome.outcomeCode === key) {
        outcomeName = outcome.outcomeName;
      }
    });
  });
  return outcomeName;
}
