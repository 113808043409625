import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'admin',
      redirect: 'tickets',
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: () => import('@/pages/Tickets'),
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('@/pages/Events'),
    },
    {
      path: '/jackpots',
      name: 'jackpots',
      component: () => import('@/pages/Jackpots'),
    },
    {
      path: '/config',
      name: 'config',
      component: () => import('@/pages/Config'),
    },
  ],
});

export default router;
