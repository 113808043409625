import { isNil } from 'lodash';

export default {
  config: (state) => state.config,
  offer: (state) => state.offer,
  user: (state) => state.user,
  info: (state) => state.info,
  isInfoVisible: (state) => !isNil(state.info),
  data: (state) => state.data,
  allHeaders: (state) => state.allHeaders,
  headers: (state) => state.headers,
  selectedHeaders: (state) => state.selectedHeaders,
  pagination: (state) => state.pagination,
  totals: (state) => state.totals,
  filters: (state) => state.filters,
  ticket: (state) => state.ticket,
  barcode: (state) => state.barcode,
  translations: (state) => state.translations,
  activeChannel: (state) => state.activeChannel || state.config.rules.defaultChannel.value,
  translation: (state) => (key) => state.translations[key] || key,
};
