<template>
  <v-main>
    <div class="">
      <v-tabs height="43"
        background-color="darkGrey"
        dark>
        <v-tab v-for="tab in tabs"
          :key="tab"
          @click.stop="updateRouter(tab)">
          {{ tab | translate }}
        </v-tab>
      </v-tabs>
      <router-view />
      <InfoMessage v-if="isInfoVisible"/>
    </div>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import InfoMessage from '@/components/InfoMessage';

export default {
  name: 'TemplateDefault',
  components: {
    InfoMessage,
  },
  data() {
    return {
      tabs: [
        'tickets',
        'events',
        'jackpots',
        'config',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'isInfoVisible',
    ]),
  },
  methods: {
    updateRouter(name) {
      if (this.$route.name === name) return;
      this.$router.push(name);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-main {
    min-height: 100vh;
}
::v-deep {
  .v-tabs-slider-wrapper {
    top: 0;
  }

  .v-tabs-slider {
    height: 3px;
    background: var(--v-primary-base);
  }
}
</style>
