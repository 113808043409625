import { forEach } from 'lodash';

const snakeToCamel = (snakeString) => snakeString.replace(/_([a-z])/g, (_, char) => char.toUpperCase());

const transformConfig = (config) => {
  const transformedConfig = {};
  forEach(config, (val) => {
    transformedConfig[snakeToCamel(val.configKey)] = val;
  });

  return transformedConfig;
};

const prepareConfigForBackend = (config) => {
  const transformedConfig = {};
  forEach(config, (val, key) => {
    if (key === 'visualizationLink') return;
    transformedConfig[key] = val.configValue === '' ? null : val.configValue;
  });

  return transformedConfig;
};

export {
  transformConfig,
  prepareConfigForBackend,
};
