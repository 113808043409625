import http from './http';
import gameHeaders from './headers';
import { getApiRoute } from '../../config';

export default {
  async loadData(page, params, config, type = 'json') {
    const headers = {
      ...gameHeaders.getProductHeaders(config),
    };
    const response = await http.get(`${getApiRoute('baseURL', config)}/admin/${page}`, {
      responseType: type,
      params,
      headers,
    });
    return response.data;
  },
  async loadTicket(barcode, config) {
    const headers = {
      ...gameHeaders.getProductHeaders(config),
    };
    const response = await http.get(`${getApiRoute('baseURL', config)}/admin/bets`, {
      params: { barcode },
      headers,
    });
    return response.data;
  },
};
