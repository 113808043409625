/* eslint-disable */
import { BusService } from '@nsftx/games-bus';
import store from '@/store';

let busService;

export default {
  init() {
    const { config } = store.getters;
    busService = new BusService({
      environment: config.environment,
      platform: config.platform,
      platformName: config.platformName,
      productName: config.productName,
      productId: config.productId,
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      adapters: [],
      clientMessageProtocol: config.messageProtocol,
    });
    // console.log('---- > Starting NEW Bus service! ');
    busService.start();
    busService.sendMessage({
      action: 'Slave.Loaded',
      data: {},
    }, true);
    // busService.sendMessage('Slave.Loaded', {});
    this.setListeners();
  },
  setListeners() {
    // console.info('---- > Set listeners ');
    window.addEventListener('Bus:User.AuthorizationChanged', (event) => {
      // console.info(' ---- > Set listeners AuthorizationChanged  ', event.detail);
      store.dispatch('setUser', event.detail);
    });
  },
  sendMessage(action, data) {
    // console.log('---- > Bus sendMessage ', action);
    busService.sendMessage({
      action,
      data,
    }, true);
  },
};
