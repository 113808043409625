import translate from './translate';
import formatDate from './formatDate';
import parseOutcome from './parseOutcome';

export default {
  install(Vue) {
    Vue.filter('translate', translate);
    Vue.filter('formatDate', formatDate);
    Vue.filter('parseOutcome', parseOutcome);
  },
};
