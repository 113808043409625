import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#009aff',
        darkGrey: '#2e3743',
        lightGrey: '#d3dadd',
        accent: '#38acf0',
        open: '#009aff',
        lost: '#cd6d6d',
        won: '#80c45a',
        jackpot: '#80c45a',
        closed: '#808b9c',
        paidout: '#bbb04c',
        expired: '#808b9c',
        pending: '#808b9c',
        rejected: '#cd6d6d',
      },
    },
  },
});
