import http from './http';
import gameHeaders from './headers';
import { getApiRoute } from '../../config';

export default {
  async loadConfig(config, channel = 'general') {
    const headers = {
      ...gameHeaders.getProductHeaders(config),
    };
    const response = await http.get(`${getApiRoute('tenantConfigURL', config)}?channel=${channel}`, { headers });
    return response.data;
  },
  async setConfig(config, data) {
    const headers = {
      ...gameHeaders.getProductHeaders(config),
    };
    const response = await http.put(`${getApiRoute('tenantConfigURL', config)}?channel=${data.channel}`, data.data,
      { headers });
    return response.data;
  },
};
