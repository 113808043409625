export default {
  common: {
    tcApi: 'https://tc-api.nsoft.com/v2/b2b/translations',
  },
  virtualsoccerdev: {
    development: {
      baseURL: 'https://games-vsts.dev.de-3.nsoft.cloud',
      tenantConfigURL: 'https://games-vsops.dev.de-3.nsoft.cloud/tenant/config',
    },
    staging: {
      baseURL: 'https://games-vsts.dev.de-3.nsoft.cloud',
      tenantConfigURL: 'https://games-vsops.dev.de-3.nsoft.cloud/tenant/config',
    },
    production: {
      baseURL: 'https://games-vsts.de-3.nsoft.cloud',
      tenantConfigURL: 'https://games-vsops.de-3.nsoft.cloud/tenant/config',
    },
  },
  virtualsoccer: {
    development: {
      baseURL: 'https://games-vsts.dev.de-3.nsoft.cloud',
      tenantConfigURL: 'https://games-vsops.dev.de-3.nsoft.cloud/tenant/config',
    },
    staging: {
      baseURL: 'https://games-vsts.staging.de-3.nsoft.cloud',
      tenantConfigURL: 'https://games-vsops.staging.de-3.nsoft.cloud/tenant/config',
    },
    production: {
      baseURL: 'https://games-vsts.de-3.nsoft.cloud',
      tenantConfigURL: 'https://games-vsops.de-3.nsoft.cloud/tenant/config',
    },
  },
};
