/* eslint-disable */
import { dateTime } from '@/utility';

export default {
  config: {},
  tenantConfig: {
    general:{},
    web: {},
    mobile: {},
    terminal: {}
  },
  offer: {},
  user: undefined,
  info: null,
  data: [],
  allHeaders: [],
  headers: [],
  selectedHeaders: {
    tickets: [
      'barcode',
      'createdAt',
      'ticketStake',
      'ticketPayout',
      'icon',
      'status',
    ],
  },
  pagination: {},
  totals: {},
  filters: [],
  ticket: [],
  barcode: '',
  // Add new column button
  addColumn: {
    text: 'mdi-filter-variant',
    value: 'columns',
    sortable: false,
    align: 'end',
  },
  translations: {},
  params: {
    limit: 25,
    startDate: new Date(`${dateTime.todayDate}T00:00:00`).toISOString(),
    endDate: new Date(`${dateTime.todayDate}T23:59:59`).toISOString(),
    page: 1,
  },
  activeChannel: null,
};
